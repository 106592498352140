import { createApp } from "vue";
import { createPinia } from "pinia";
import "bootstrap/scss/bootstrap.scss";
import App from "./App.vue";
import router from "./router";
import "animate.css";
import "./style/index.scss";
import "./assets/iconfont/iconfont.css";
import WOW from "wow.js";
const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount("#app");

// app.use(booststrapConfig);

const wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  offset: 0,
  mobile: true,
  live: true,
});
wow.init();
